/* @import url('https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans&family=Roboto:wght@300;400&display=swap'); */
body{
  margin-left: auto;
  margin-right: auto;
  background-color: #E3e3e3;
}
.navbar {
  background-color: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 70px;
  /* height: 80px; */
  /* max-width: 1500px; */
}

.nav-logo {
  /* position: relative;
    cursor: pointer;
    text-decoration: none;
    right: 5%;
    z-index: 999;
    height: 65px;
    width: fit-content; */
}

.scroll-button button{
  color: #E3e3e3;
}

.nav-menu {
  /* display: flex;
  list-style: none;
  text-align: center; */
  display: flex;
    list-style: none;
    position: relative;
    /* left: 3%; */
    margin-top: auto;
    margin-bottom: auto;
}

.nav-links {
  /* color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-family: 'Open Sans', sans-serif; */
  /* font-family: madefor-display,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif; */
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.02em;
    text-shadow: none;
    color: #FFFFFF;
    text-decoration: none;
    padding: 12px;
    font-weight: 500;
}
.nav-links:hover{
    color:#ddb15f;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  /* margin-right: 2rem; */
  /* font-size: 20px; */
  font-size: 10px;
  text-decoration: none;
  margin-top: auto;
  margin-bottom: auto;
}
/* .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
} */
/* .nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
} */

/* .nav-item:hover:after {
  width: 100%;
  background: #441e39;
}

.nav-item .active {
  color: #441e39;
} */
.nav-item .active {
    color: #e6ca75;
    text-decoration: none;
  } 
.nav-icon {
  display: none;
}

@media screen and (max-width:1024px){
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border-top: 1px solid #fff; */
    position: absolute;
    top: 79px;
    /* left: -110%; */
    left: -1000px;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #333;
    padding: 10px;
    left: 0px;
    margin: 0;
    text-align: center;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #e6ca75;
    border: none;
  }

  .nav-links {
    padding: 10px;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0px;
    right: 25px;
    cursor: pointer;
    color: #ffff;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-logo {
    position: relative;
    /* right: 35%; */
    cursor: pointer;
    text-decoration: none;
  }
  .scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

}




@media screen and (max-width: 960px) {
 /* @media only screen and (min-width:321px) and (max-width:768px){ */
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border-top: 1px solid #fff; */
    position: absolute;
    top: 79px;
    left: -1000px;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #333;
    padding: 10px;
    left: 0px;
    margin: 0;
    text-align: center;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #e6ca75;
    border: none;
  }

  .nav-links {
    /* padding: 1.5rem; */
    padding: 10px;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0px;
    right: 25px;
    cursor: pointer;
    color: #ffff;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-logo {
    position: relative;
    /* right: 19%; */
    /* right: 15%; */
    cursor: pointer;
    text-decoration: none;
  }
  .scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}
}

.sub-menu {
  position: absolute;
  background-color: #fff;
  width: 220px;
  padding: 15px 15px;
  margin: 17px;
  left: 0;
  top: 50px;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 0 20px #555555;
  box-shadow: 0 0 20px #555555;
}

.sub-menu li {
  display: block;
  text-align: left;
}

.sub-menu li a {
  color: #555;
  font-weight: 600;
  padding: 7px 10px;
  font-size: 13px;
}

.sub-menu li:last-child {
  float: none !important;
}

.sub-menu li {
  display: block;
}

.sub-menu li a {
  color: #555;
  padding: 8px;
  font-size: 18px;
  font-weight: 600;
}

.sub-menu a {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.sub-menu li:hover a {
  color: #F28123;
}

.sub-menu li a {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.sub-menu li:hover a {
  color: #F28123 !important;
}

 
/* .search{
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background: transparent;
  transition: 0.5s;
}
.search .icon{
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  color: #FFF;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.search .icon:before{
  content: '\f002';
  font-family: fontAwesome;
} */
/*.search.active .icon:before{
  content: '\f00d';
  font-family: fontAwesome;
 } */
/* .search.active{
  width: 100vw;
  height: 100vh;
}
.form{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // display: none; 
  visibility: hidden;
}
.form input{
  background: transparent;
  color: #FFF;
  border: none;
  border-bottom: 4px solid #FFF;
  outline: none;
  font-size: 40px;
  padding: 10px 0;
  
}
.search.active .form{
  display: block;  
  visibility: visible;
}
.search input::placeholder{
  color: #FFF;
}  */

/* .box{
  position: relative;
}


.box:hover input{
  width: 350px;
  background: transparent;
  border-radius: 10px;
}
.box i{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%,-50%);
  font-size: 26px;
  color: #ffd52d;
  transition: .2s;
}
.box:hover i{
  opacity: 0;
  z-index: -1;
}
 */
 
 /* .icons{
  border: 1px solid red;
  padding: 1%;
  margin-left: -10%;
  width: 100%;
  display: inline-flex; 
  text-align: center;
 }
 .icons div{
  border: 1px solid black;
  margin-left: 10%;
  padding: 10px;
  cursor: pointer;
 }

 .search-form{
  position: absolute;
  top: 110%;
  margin-left: 50%;
  right: 2rem;
  width: 50rem;
  height: 4rem;
  align-items: center;
  display: flex;
  overflow: hidden;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
 }

 .search-form input{
  height: 100%;
  width: 100%;
 } */

 .search{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  z-index: 10;
  cursor: pointer;
 }

 .searchBox{
  position: absolute;
  right: -100%;
  width: 100%;
  height: 100%;
  display: flex;
  background: red;
  align-items: center;
  padding: 0 30px;
  transition: 0.5s ease-in-out;
 }

.searchBox input{
    width: 100%;
    outline: none;
    border: none;
    height: 50px;
    font-size: 1.25em;
    background: #fff;
    border-bottom: 1px solid black ;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  /* min-width: 160px; */
  min-width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #333;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}
.dropdown-content a:hover{
  color: #f79500;
}
.dropdown:hover .dropdown-content {
  display: block;
}


/* ==== updated ==== */
nav.nav-bar {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
}

.nav-social-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.gold-today-price p {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.nav-socail a.social-btns {
  padding: 12px 10px;
  display: inline-block;
  color: #989898;
}
.nav-socail a.social-btns:hover {
  color: #ddb15f;
}
span.gold-today-price {
  background: white;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 4px;
}

.nav-container {
  background: #1d1d1d9e;
  justify-content: space-between;
  padding: 10px 15px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px #afaf7459;
  position: relative;
}
a.nav-logo img {
  max-width: 165px;
}
section.showcase {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

video.banner-video {position: absolute;left: 0;top: 0;min-width: 100%;min-height: 100vh;}

.overlay {
  background-color: #00000072;
  height: inherit;
}

.banner-content {
  position: relative;
  max-width: 720px;
  padding: 100px 0;
}
.banner-content h1 {
  font-size: 58px;
  font-weight: 700;
}

.banner-content p {
  font-size: 20px;
  margin: 30px 0;
}

.banner-foot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.banner-foot a {
  min-width: 180px;
}

button.btn.btn-home {
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  background: #E5CA75;
  color: #4f1e00;

}


@media (max-width:600px) {
  .nav-social-container {
    display: none;
}

nav.nav-bar {
    top: 15px;
}
}
/* ==== updated ==== */