@font-face {
   font-family: 'Albertus Nova';
   src: url('../fonts/AlbertusNova.eot');
   src: local('Albertus Nova'), local('AlbertusNova'),
       url('../fonts/AlbertusNova.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AlbertusNova.woff2') format('woff2'),
       url('../fonts/AlbertusNova.woff') format('woff'),
       url('../fonts/AlbertusNova.ttf') format('truetype');
   font-weight: normal;
   font-style: normal;
}
* {
   margin: 0;
   padding: 0;
   -webkit-font-smoothing: antialiased;
   font-family: "Albertus Nova";
}

body {
   /* font-family: "Poppins", sans-serif; */
   overflow-x: hidden;
   font-family: "Albertus Nova";
}

.showcase {
   width: 100%;
   /* height: 500px; */
   position: relative;
   color: white;
   text-align: center;
}

.showcase img {
   width: 100%;
   height: 100%;
   /* position: absolute; */
   top: 0;
   left: 0;
   /* z-index:99 */
}

.showcase .overlay {
   width: 100%;
   /* height: 500px; */
   /* background-color:rgb(55 58 63 / 70%);; */
   /* background-color: rgb(128 127 129 / 15%); */
   position: absolute;
   top: 0;
   left: 0;
   /* z-index:999 */
}

.showcase h2 {
   margin-top: 170px;
   font-size: 3em;
}

.showcase p {
   margin-top: 8px;
   /* font-size: 1.2em; */
   font-size: 19px;
}
.btn-home {
   border: 1px solid #242424;
   background-color: #242424;
   color: #ffff;
   font-size: 12px;
   border-radius: 0px;
   padding: 10px;
}
.btn-home:hover {
   color: #3d3d3d;
   background-color: transparent;
}
.enquiry-btn button {
   background-color: #e5ca75;
   font-size: 12px;
   font-weight: 600;
   width: 100px;
   height: 40px;
}
.enquiry-btn button a {
   color: #441e39;
}
.enquiry-btn button a:hover {
   color: #242424;
}
.enquiry-btn button:hover {
   background-color: transparent;
   border: 1px solid #242424;
}

.enquiry-btn2 button {
   background-color: #e5ca75;
   color: #441e39;
   font-size: 12px;
   font-weight: 600;
   width: 150px;
   height: 40px;
}
.enquiry-btn2 button a {
   color: #441e39;
}
.enquiry-btn2 button a:hover {
   color: #242424;
}
.enquiry-btn2 button:hover {
   background-color: transparent;
   border: 1px solid #242424;
   color: #242424;
}

.box {
   position: relative;
   box-sizing: border-box;
   /* padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%; */
   /* flex-grow: 1; */
   display: var(--l_display, var(--container-display));
   /* grid-template-rows: 1fr; */
   grid-template-columns: minmax(0px, 1fr);
   --container-display: grid;
   background-color: rgb(229, 202, 117);
   border-radius: 20px;
}
.box h2 {
   font-weight: 600;
   text-decoration: none;
   text-align: left;
   font-size: x-large;
}
.box p {
   font-size: small;
}
.button {
   border: 1px solid red;
}
.box-2 {
   background-color: rgb(229, 202, 117);
   border-radius: 20px;
}
.box-2 h2 {
   font-weight: 600;
   text-decoration: none;
   text-align: left;
   font-size: x-large;
}
.box-2 p {
   font-size: small;
}
/* .btn-style{
   
    width: 60%;
    text-align: center;
    padding: 4px;
    border-radius: 20px;
 }
 .btn-style:hover{
    color: #ffff;
   
 } */
.btn-style a {
   background-color: #ffff;
   text-decoration: none;
   color: #242424;
   font-size: 12px;
   border: none;
   width: 60%;
   text-align: center;
   border-radius: 20px;
   text-decoration: none;
   padding-left: 20px;
   padding-right: 20px;
   padding-top: 10px;
   padding-bottom: 10px;
}
.btn-style a:hover {
   color: #ffff;
   background-color: #242424;
}
.btn-style {
   color: #242424;
   background: none;
   border: none;
}
/* span .btn{
       border: 1px solid red;
       position: absolute;
 } */

/* div h4 {
    position: absolute;
    margin-bottom: 10%;
    right: 50px;
    cursor: pointer;
    background-color: rgb(229, 202, 117);
    font-size: small;
    color: #242424;
    padding-left:3%;
    padding-right: 3%;
    padding-top: .5%;
    padding-bottom: .5%;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bolder;
    text-decoration: none;
    color: #242424;
} */

/* div h4 {
   position: absolute;
   margin-bottom: 10%;
   right: 50px;
   width: 20%;
   padding: 1%;
   cursor: pointer;
   background-color: rgb(229, 202, 117);
   font-size: small;
   color: #242424;
   border-radius: 10px;
   text-transform: uppercase;
   font-weight: bolder;
   text-decoration: none;
}
div h4 a {
   text-decoration: none;
   color: #242424;
}
div h4 a:hover {
   color: #242424;
}
div h4:hover {
   background-color: transparent;
} */

/* .bg-apply{
    background-image: url("../Images/bag-apply.png");    
object-fit: cover;
width: 100%;
} */
.bg-image {
   background-image: url("../Images/bag-apply.png");
   width: 100%;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
}
/* width: 1895px;
height: 1592px; */
.text-style p {
   color: #ffffff;
   /* font-family: madefor-text-mediumbold, helveticaneuew01-45ligh, helveticaneuew02-45ligh, helveticaneuew10-45ligh,
      sans-serif; */
   font-weight: normal;
   line-height: 1.6;
   background-color: transparent;
   font-size: 12px;
}
.text-style a button {
   border: none;
   color: #441e39;
   background-color: rgb(229, 202, 117);
   border-radius: 10px;
   width: 30%;
   font-size: 14px;
   font-weight: 600;
}
.text-style a button:active {
   color: #441e39 !important;
   border: none !important;
}
.text-style a button:hover {
   background: none;
}

/* slider */

.bg-footer {
   background-color: rgb(229, 202, 117);
   padding: 5%;
}
.footer-text p {
   font-size: 11px;
   color: #441e39;
   /* font-family: madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi,
      sans-serif; */
   line-height: 2;
}
.lists li {
   color: #000000;
   list-style: none;
}

.swiper-slide {
   text-align: center;
   font-size: 18px;
   /* border: 1px solid black; */
   display: flex;
   justify-content: center;
   align-items: center;
}

.swiper-slide img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.swiperSlide {
   border: 1px solid red !important;
   width: 75% !important;
}

.bg-about {
   background-color: #441e39;
   padding-top: 120px;
}
.lead {
   color: #e5ca75;
   /* font-family: madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi,
      sans-serif; */
   text-align: center;
   font-size: 15px;
   font-weight: 600;
}
.abt {
   padding: 5%;
}
.info {
   margin: 8%;
}
.info h3 {
   color: #441e39;
   text-transform: uppercase;
   font-weight: 700;
   /* font-family: madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi,
      sans-serif; */
   /* font-size: 40px; */
   text-decoration: none;
   text-align: left;
}
.info-lists li {
   color: #441e39;
   font-family: madefor-text-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi, sans-serif;
   text-align: left;
   line-height: 1.6;
   font-weight: bold;
   font-size: 13px;
}
.our-vision h3 {
   color: #000000;
   padding: 4%;
   font-weight: 700;
}
.our1 {
   background-color: #ffffff;
   padding: 5%;
   height: 380px;
}
.our1 h3 {
   font-size: medium;
   position: relative;
   bottom: 15px;
   right: 15px;
   font-weight: 700;
}
.our1 p {
   font-size: 11px;
   /* margin: 10px; */
}
.our1 ul li {
   font-size: 11px;
   padding: 0px;
   margin: -3px;
}
.form-calculate h1 {
   text-align: left;
   /* font-family: madefor-display-bold, helveticaneuew01-65medi, helveticaneuew02-65medi, helveticaneuew10-65medi,
      sans-serif; */
   color: #441e39;
   font-weight: 700;
   font-size: 40px;
   /* line-height: 40px; */
}
.form-btn button:hover {
   background-color: #ffffff;
   border: 1px solid #242424;
   color: #000000;
}

.form-btn button {
   width: 65%;
   /* width: 250px; */
   background-color: #000000;
   border: none;
   height: 30px;
   font-size: 11px;
   border-radius: 0;
   font-weight: 600;
}
.bg-branch {
   background-color: rgb(180, 165, 176);
   padding-top: 120px;
}
/* .branch-box{
   height: 200px;
} */
/* .branch-box1{
   height: 200px;
   background-color: #E5CA75;
} */
.branch-box2 h1 {
   color: #441e39;

   font-weight: 900;
}
/* .branch-box1 h3{
   color: #441E39;
   font-weight: bolder;
   font-size: 20px;
   font-family: sans-serif;
   letter-spacing: .5px;
} */
.branches h3 {
   color: #441e39;
   font-weight: bolder;
   font-size: 20px;
   /* font-family: sans-serif; */
   letter-spacing: 0.5px;
}
.branches p {
   font-weight: 600;
   color: #441e39;
   font-size: 12px;
}
/* .branch-box1 p{
   font-weight: 600;
   color: #441E39;
    font-size: 12px;
 } */
.branches button {
   font-weight: 600;
   color: white;
   background-color: #441e39 !important;
   width: 40%;
   border: none;
   border-radius: 20px;
   height: 26px;
   font-size: 8px;
   text-transform: uppercase;
}
/* 
 
 .branch-box1 button:hover{
   color: white;
   background-color: #000000 !important;
 }
.bg-cal{
   background-image: url("../Images/bg-cal.jpg");
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
}
.label{
   color: #242424;
   font-size: 13px;
}
.label-partners{
   font-size: 14px;
   font-family: madefor-display,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;;
}


/* .label-form{

   font-size: 14px;
   font-family: madefor-display,helveticaneuew01-45ligh,helveticaneuew02-45ligh,helveticaneuew10-45ligh,sans-serif;;
} */
.input-partners {
   border: 1px solid #000000;
   height: 28px;
}
.input-form:active .input-form:focus {
   border-color: black !important;
}
.input-form {
   border: 1px solid red;
}
.input {
   border-bottom: 1px solid black;
   height: 35px;
   border-radius: 0px;
}
.btn-partner button {
   background-color: #441e39;
   border-radius: 20px;
   font-weight: 700;
   border: none;
   font-size: 12px;
   width: 100px !important;
}
.btn-form button {
   background-color: transparent;
   border: 1px solid white !important;
   font-weight: 700;
   border: none;
   font-size: 12px;
   width: 40% !important;
   height: 40px;
}
.btn-form button:hover {
   border: 1px solid white !important;
}
.card-button button {
   background-color: #e5ca75 !important;
   border-radius: 20px;
}
.card-button button:hover {
   background-color: #000000 !important;
   color: #ffffff !important;
}

.btn-partner button:hover {
   background-color: transparent;
   border: 1px solid #000000;
}
.btn-service button {
   background-color: transparent;
   border-radius: 20px;
   color: #000000;
   /* font-weight: 700;
 */
   height: 35px;
   width: 100px;
   font-size: 11px;
}
.btn-service button:hover {
   color: #ffffff;
   background-color: #000000;
}
/* .form-branch{
   border: 1px solid red;
} */
/* .branch-box{
   height: 200px;
} */
#adress-info {
   margin-left: 3% !important;
   margin-right: 3% !important;
}
.branch-box h3 {
   /* color: #441e39;
   font-weight: 800;
   margin-top: auto;
   margin-bottom: auto; */
   /* font-size: 20px; */
}
.branch-button button {
   border: 1px solid black;
   border-radius: 20px;
   background-color: #e5ca75 !important;
   color: #000000;
   font-weight: 600;
}
.branch-button button:hover {
   background-color: #441e39 !important;
   color: #fff !important;
}
.branch-button button:focus {
   background-color: #441e39 !important;
   color: #fff !important;
   box-shadow: none !important;
}
.form-branch button {
   border: 1px solid black;
   margin: 10px;
   border-radius: 20px;
   /* height: 35px; */
   background-color: #e5ca75 !important;
   color: #000000;
   font-weight: 600;
   /* width: 40%; */
   /* padding: auto; */
   /* margin-top: 10%; */
}

.swiper-pagination {
   position: static !important;
}
.swiper-pagination-bullets {
   position: static !important;
}
.swiper-pagination-horizontal {
   position: static !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
   border-color: #441e39 !important;
   box-shadow: 0 0 0 0.2rem rgba(255, 47, 69, 0.25) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
   border-color: #441e39 !important;
   background-color: #441e39 !important;
}

.custom-control-input:active ~ .custom-control-label::before {
   background-color: #441e39 !important;
   border-color: #441e39 !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
   border-color: #441e39 !important;
}

.custom-control-input-green:not(:disabled):active ~ .custom-control-label::before {
   background-color: #441e39 !important;
   border-color: #441e39 !important;
}
.form-branch button:hover {
   color: white;
   background-color: #000000 !important;
}
.bg-slider {
   background-image: url("../Images/bg-cal.jpg");
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   position: relative;
   bottom: 0px;
   padding: 11px;
   top: 0px;
}
.bg-partners {
   background-image: url("../Images/bg-partner.jpg");
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   padding-top: 120px;
}
.scroll-button button {
   border: 1px solid #ffffff;
   background-color: transparent;
   font-size: 12px;
   height: 33px;
   width: 90px;
   border-radius: 10px;
}
.scroll-button button:hover {
   color: #ffffff;
   background-color: #000000;
   border: none;
}
.btn-primary:hover {
   color: #fff;
   background-color: transparent !important;
   border-color: black !important;
}

/* Slider */
/* ImageSlider.css */
.slider-container {
   max-width: 800px; /* Set your desired width here */
   margin: 0 auto; /* Center the slider */
}

.slider {
   position: relative;
   overflow: hidden;
   transition: transform 0.5s ease-in-out;
}

.slides {
   transition: opacity 0.5s ease-in-out;
   display: none;
   /* display: none; */
}

.slide.active {
   display: block;
}

img {
   /* max-width: 100%; */
   width: 100%;
   height: auto;
}

.prev-btn,
.next-btn {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   color: #fff;
   border: none;
   padding: 10px;
   cursor: pointer;
}

.prev-btn {
   left: 0px;
}

.next-btn {
   right: 0px;
}
.collapsed {
   display: none;
}

.expanded {
   display: block;
}
.questions p::after {
   color: #e5ca75;
}
.faq .active {
   background-color: #441e39;
   color: #fff;
}
.faq button:hover {
   border: 1px solid black;
}
.faq button {
   border: 1px solid transparent;
   font-size: smaller;
}

/* .faqs .active{
   background-color: #441E39;
   color: #fff;
 }
 .faqs button:hover{
   border: 1px solid black;
 }
 .faqs button{
   border: 1px solid transparent;
   font-size: smaller;
 } */

/* ===== service page  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type="number"] {
   -moz-appearance: textfield;
}
/* ======== custom style */
section.showcase {
   height: 100vh;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: 650px;
}
.foot-bg {
   background: linear-gradient(90deg, #ff98004d 1.59%, #ffc10769 84.61%);
   padding-bottom: 45px;
}
.img-calc{
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}
video.banner-video {
   position: absolute;
   left: 0;
   top: 0;
   min-width: 100%;
   min-height: 100vh;
}

.overlay {
   background-color: #00000072;
   height: 100%;
}

.banner-content {
   position: relative;
   max-width: 740px;
   padding: 100px 0;
}

@media (max-width: 767px) {
   .banner-content h1 {
      font-size: 32px;
   }

   .showcase p {
      font-size: 14px;
      margin-top: 30px;
   }

   .banner-content {
      width: 95%;
      padding: 50px 0 20px;
   }

   section.showcase {
      height: auto;
      min-height: 600px;
   }
}
/* ======== custom style */

/* ===== service page  */
section.mid-section {
   background: #f6f5ef;
   padding: 50px 0px;
}

.left-click-content {
   position: relative;
   display: flex;
   align-items: center;
}

/* .lft-grid-img {display: none;} */

.sub-hading h2 {
   font-size: 32px;
   line-height: 48px;
   font-weight: 500;
   margin-bottom: 40px;
   text-align: center;
}
.sub-hading h2 strong {
   color: #4f1e00;
}
.get-price h5 {
   font-size: 18px;
}

.scl-lft-grid {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 5px;
   margin-top: 15px;
   margin-bottom: 15px;
}

section div.social-anc a button {
   min-width: 150px;
   border: none;
   background: #cd865c;
   color: white;
   border-radius: 0;
   min-height: 35px;
   font-weight: 500;
}

.social-anc {
}

button.btn.btn-outline-dark {
}

.lft-grid-img img {
   /* width: 100px; */
   height: auto;
}

.form-section {
   height: 100%;
   display: flex;
   flex-flow: column;
   justify-content: space-between;
   padding-left: 15px;
}

.form-input {
   position: relative;
}

.form-input label {
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 3px;
}

.form-input input {
   width: 100%;
   min-height: 48px;
   border: 1px solid #e9e9e9;
   outline: none;
   padding: 10px;
   font-size: 14px;
   font-weight: 500;
   font-family: inherit;
}

.form-input select {
   width: 100%;
   min-height: 48px;
   border: 1px solid #e9e9e9;
   outline: none;
   padding: 10px;
   font-size: 14px;
   font-weight: 500;
   font-family: inherit;
   -webkit-appearance: none;
   -moz-appearance: none;
   background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
   background-repeat: no-repeat;
   background-position-x: 99%;
   background-position-y: 11px;
   background-color: white;
}

.form-submit {
   margin-top: 30px;
}

.form-submit button {
   border: none;
   width: 100%;
   min-height: 48px;
   background: #e5ca75;
   color: #4f1e00;
   font-weight: 500;
   font-size: 18px;
}

.left-click-content {
   /* height: 100%; */
   /* justify-content: space-between; */
}

.form-container {
   display: flex;
   flex-flow: column;
   gap: 11px;
}
/* ===== service page  */
/* ===== service content page  */
section.services-section {
   padding: 50px 0px;
   background: white;
}

.srv-grid-cont {
   display: flex;
   gap: 15px;
   flex-flow: wrap;
   margin-top: 100px;
}

.srv-gird-content {
   display: flex;
   flex-flow: column;
   gap: 10px;
   background: white;
   width: calc(25% - 12px);
   position: relative;
   box-shadow:
      rgba(0, 0, 0, 0.16) 0px 3px 6px,
      rgba(0, 0, 0, 0.23) 0px 3px 6px;
   border-radius: 6px;
   padding-top: 70px;
}

.src-ico {
   width: 100px;
   height: 100px;
   background: #f6c42c;
   border-radius: 100px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   left: 0;
   right: 0;
   margin: auto;
   top: -50px;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
   border: 2px solid white;
}

.src-ico img {
   width: 80px;
   height: 70px;
}

.srv-text {
   text-align: center;
   padding: 12px 10px;
}

.srv-text h3 {
   margin-bottom: 10px;
   font-size: 18px;
   font-weight: 500;
}

.srv-text p {
   font-size: 13px;
   color: #737272;
   margin: 0;
}
.srv-gird-button {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin-top: 50px;
}

.srv-gird-button a {
   border: none;
   background: #e5ca75;
   color: #4f1e00;
   font-weight: 500;
   font-size: 16px;
   display: block;
   padding: 10px 30px;
   border-radius: 4px;
}
/* ===== service content page  */
/* ===== calculate section  */
section.full-width-calculate {
   position: relative;
}

img.img-fluid.img-cal-mob {
   display: none;
}

.calculate-now-btn {
   border: none;
   background: #e5ca75;
   color: #4f1e00;
   font-weight: 500;
   font-size: 16px;
   display: block;
   padding: 10px 30px;
   border-radius: 4px;
   position: absolute;
   bottom: 50px;
   right: 8%;
}

.calculate-now-btn a {
   color: #4f1e00;
}
/* ===== calculate section  */
/* ===== Gold Return Section  */
section.gold-return-section {
   padding: 80px 0px;
   /* backdrop-filter: blur(1px); */
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
}

section.gold-return-section:before {
   content: "";
   position: absolute;
   background: #0000008f;
   width: 100%;
   height: 100%;
   z-index: 0;
   top: 0;
   backdrop-filter: blur(2px);
}

.gld-return-img img {
   border-radius: 4px;
   box-shadow:
      rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.gold-return-content {
   color: white;
}

.gold-return-content h3 {
   font-size: 32px;
   margin-bottom: 30px;
}

.gold-return-content p {
   font-size: 14px;
}

.gold-return-btn {
   border: none;
   background: #e5ca75;
   color: #4f1e00;
   font-weight: 500;
   font-size: 16px;
   display: block;
   padding: 10px 30px;
   border-radius: 4px;
   width: 150px;
   margin-top: 30px;
}

.gold-return-btn a {
   color: #4f1e00;
}
/* ===== Gold Return Section  */
/* ===== carausal Section  */
section.slider-section {
   background: white;
   padding: 80px 0px;
}

div#carouselExampleControls {
}

section.carausel-main-section .carousel {
   max-width: 80%;
   margin: auto;
}

section.carausel-main-section .carousel img {
   width: 100%;
   margin: auto;
   display: block;
   height: 600px;
   object-fit: cover;
}

section.carausel-main-section .carousel-control-prev {
   left: -140px;
   background: white;
   box-shadow:
      rgba(9, 30, 66, 0.25) 0px 1px 1px,
      rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
   background: #e5ca75;
}

section.carausel-main-section .carousel-control-next {
   right: -140px;
   background: white;
   box-shadow:
      rgba(9, 30, 66, 0.25) 0px 1px 1px,
      rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
   background: #e5ca75;
}

span.carousel-control-prev-icon {
   filter: invert(1);
}

span.carousel-control-next-icon {
   filter: invert(1);
}
/* ===== carausal Section  */
/* ===== swiper Section  */
section.multi-slider-secition {
   background: #f6f5ef;
   padding: 50px 0px;
}

section.multi-slider-secition span.swiper-pagination-bullet.swiper-pagination-bullet-active {
   background: #4f1e00;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
   margin-top: 30px;
}
/* ===== swiper Section  */

/* custom===== */
.bg-cal {
   padding-top: 120px;
}
.v-gap-30 {
}
/* custom===== */

/* ======= about us ======= */
.inner-pages {
   padding-top: 150px;
   background: white;
}

.abt-cont h4 {
   font-size: 70px;
   text-align: center;
   font-weight: 400;
   margin-bottom: 40px;
}

.abt-cont p {
   margin-bottom: 30px;
   font-size: 16px;
   text-align: center;
}

.abt-cont {
   position: relative;
}

.history-content {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.hst-img {
   width: 50%;
}

.hst-right {
   width: 50%;
   padding-left: 65px;
}

section.history-section {
   background: #f6f5ef;
   padding: 50px 0px 70px;
}

.hst-img img {
   border-radius: 10px;
   box-shadow:
      rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
   position: relative;
   z-index: 1;
}

.hst-right h5 {
   font-size: 32px;
   margin-bottom: 30px;
}

.hst-right ul li p {
   font-size: 14px;
   margin-bottom: 0;
}

.hst-right li {
   margin-bottom: 14px;
}

.hst-right ul {
   margin: 0;
}
section.about-content {
   padding: 40px 0px;
}

/*! CSS Used from: https://mysleepwell.com/css/style.css */

.blob {
   will-change: transform, opacity;
}
.blob-four,
.blob-five {
   background: white;
   transform-origin: center;
   float: left;
   margin: 20px;
}
.blob-four {
   background: radial-gradient(circle at 30% 60%, rgba(255, 255, 255, 0.6) 25%, rgba(255, 255, 255, 0) 60%),
      radial-gradient(circle at 35% 35%, rgba(255, 255, 255, 0.6) 15%, rgba(255, 255, 255, 0) 60%),
      radial-gradient(circle at bottom, rgba(218, 40, 28, 0.2), rgba(255, 255, 255, 0) 50%),
      radial-gradient(circle at bottom left, rgba(218, 40, 28, 0.02), rgba(255, 255, 255, 0) 50%),
      radial-gradient(circle at left, rgba(218, 40, 28, 0.2), rgba(255, 255, 255, 0) 50%),
      radial-gradient(circle at bottom right, rgba(64, 100, 201, 0.01), rgba(255, 255, 255, 0) 70%),
      radial-gradient(circle at right, rgba(64, 125, 201, 0.2), rgba(255, 255, 255, 0) 90%),
      radial-gradient(circle at top, rgba(140, 70, 153, 0.2), rgba(255, 255, 255, 0) 70%),
      radial-gradient(circle at top left, rgba(140, 70, 153, 0.2), rgba(255, 255, 255, 0) 70%);
   border-radius: 100% 75% 100% 75%/100% 87% 90% 69%;
   width: 400px;
   height: 600px;
}
.blob-five {
   background: radial-gradient(circle at 45% 35%, rgba(255, 255, 255, 0.1) 15%, rgba(255, 255, 255, 0) 70%),
      radial-gradient(circle at center left, rgba(250, 225, 14, 0.1), rgba(255, 255, 255, 0) 90%),
      radial-gradient(circle at top right, rgba(255, 102, 0, 0.05), rgba(255, 255, 255, 0) 70%),
      radial-gradient(circle at bottom right, rgba(255, 102, 0, 0.1), rgba(255, 255, 255, 0) 90%),
      radial-gradient(circle at center right, rgba(218, 40, 28, 0.1), rgba(255, 255, 255, 0) 80%);
   width: 400px;
   height: 600px;
   border-radius: 59% 39% 57% 42% / 54% 34% 64% 45%;
}
.shop-by-hero-bg {
   position: absolute;
   right: 50%;
   top: 0;
}
@media (max-width: 991px) {
   .shop-by-hero-bg {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      overflow: hidden;
      width: 100%;
   }
}
.shop-by-hero-bg .blob1 {
   max-width: 425px;
   max-height: 613px;
   position: absolute;
   top: -100px;
   transform: rotate(-75deg);
   left: -400px;
}
@media (max-width: 991px) {
   .shop-by-hero-bg .blob1 {
      left: -54%;
      position: absolute;
      top: -12%;
      transform: rotate(299deg) scale(0.9);
   }
}
.shop-by-hero-bg .blob2 {
   height: 814px;
   width: 553px;
   transform: rotate(50deg);
   top: -245px;
   position: absolute;
}
@media (max-width: 991px) {
   .shop-by-hero-bg .blob2 {
      top: -27%;
      transform: rotate(50deg) scale(0.7);
      left: 20%;
   }
}
.store-locator-hero .store-hero-blob .shop-by-hero-bg .blob2 {
   height: 770px;
   width: 530px;
}
@media (max-width: 991px) {
   .store-locator-hero .store-hero-blob .shop-by-hero-bg .blob2 {
      width: 790px;
      transform: rotate(170deg) scale(0.7);
      left: 20%;
   }
}
/* ======= about us ======= */
/* ======= History ======= */
.hst-img {
   position: relative;
}

span.rect-dots-top {
   position: absolute;
   box-shadow: none;
   width: 150px;
   left: -60px;
   top: -30px;
}

span.rect-dots-top img {
   box-shadow: none;
   filter: contrast(0.7);
}

span.rect-dots-bottom img {
   box-shadow: none;
   filter: contrast(0.7);
}

span.rect-dots-bottom {
   position: absolute;
   box-shadow: none;
   width: 150px;
   right: -60px;
   bottom: -30px;
   z-index: 0;
}
section.about-content {
   padding: 40px 0px;
}

.hst-right li {
   position: relative;
}

.hst-right ul {
   list-style: none;
}

.hst-right li:before {
   content: "";
   position: absolute;
   height: 8px;
   width: 8px;
   background: #bb8e33;
   border-radius: 10px;
   left: -20px;
   top: 8px;
}
/* ======= History ======= */
/* ======= our vision ======= */
section.vision-section {
   padding: 80px 0px;
}

.vsn-grids-outer {
   background: linear-gradient(90deg, #ff98004d 1.59%, #ffc10769 84.61%);
   border-radius: 10px;
   padding: 5px;
   width: calc(50% - 8px);
   box-shadow:
      rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.vsn-grids {
   background: white;
   padding: 25px;
   border-radius: 6px;
   height: 100%;
}

.vison-grid-cont {
   display: flex;
   flex-flow: wrap;
   gap: 15px;
}

.vsn-grids h4 {
   font-size: 24px;
   margin-bottom: 30px;
}

.vsn-grids p {
   font-size: 13px;
   color: #222222;
   margin-bottom: 15px;
}

.vsn-grids ul li span {
   font-size: 12px;
}

.vsn-grids ul {
   list-style: none;
   padding-left: 10px;
}

.vsn-grids li {
   position: relative;
}

.vsn-grids li:before {
   content: "";
   position: absolute;
   height: 6px;
   width: 6px;
   background: #bb8e33;
   border-radius: 10px;
   left: -12px;
   top: 10px;
}
/* ======= our vision ======= */
/* ======= calculator  ======= */
.cal-outer {
   padding: 30px 0px 60px;
}

.calc-heading h4 {
   font-size: 22px;
   margin-bottom: 20px;
}

.form-input sup {
   color: red;
}

.v-gap-15 {
   gap: 15px 0px;
}
.v-gap-10 {
   gap: 10px 0px;
}
.v-gap-20 {
   gap: 20px 0px;
}
.v-gap-30 {
   gap: 30px 0px;
}
.inner-pages.calc-main {
   background: #f6f5ef;
}
/* ======= calculator  ======= */
/* ======= Branches  ======= */
.branches-bg {
   background: linear-gradient(163deg, #ffffffab 1.59%, #ffc1079c 84.61%);
}
.branchs-grid {
   box-shadow:
      rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
   height: 100%;
   border-radius: 10px;
   overflow: hidden;
   background: white;
   display: flex;
   min-height: 200px;
}

.branches-outer {
   padding: 12px;
   height: 100%;
   display: flex;
   flex-flow: column;
   justify-content: space-between;
}

.branches-outer h3 {
   font-size: 16px;
   font-weight: 600;
   margin-bottom: 8px;
   color: #81572e;
   text-transform: uppercase;
}

.branches-outer p {
   font-size: 12px;
   margin-bottom: 5px;
}

.branch-ifreame {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
}
.top-branchs {
   padding-bottom: 80px;
}
.branch-ifreame iframe {
   height: 100%;
   width: 100%;
   min-height: 160px;
}

.branches-outer a button {
   font-size: 14px;
   min-width: 150px;
   border: none;
   background: #cd865c;
   color: white;
   border-radius: 4px;
   min-height: 32px;
   box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.branches-outer a {
   /* margin-top: 30px; */
   display: block;
}
.branch-box {
   box-shadow:
      rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
   height: 100%;
   border-radius: 10px;
   overflow: hidden;
   background: white;
   min-height: 200px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-image: url("../Images/Icons/map.svg");
   background-size: contain;
}

.ourBranchs h3 {
   font-size: 32px;
   font-weight: 600;
   color: #4f1e00;
   padding: 4px 30px;
   margin-bottom: 10px;
}

.branch-btns {
   display: flex;
   align-items: center;
   justify-content: center;
}

.branch-btns button {
   color: #4f1e00;
   border: 1px solid #4f1e00;
   border-radius: 20px;
   padding: 3px 20px;
   background: white;
   font-size: 12px;
   background: #e5ca75;
}
.avalilable-area {
   background-image: url("../Images/Icons/map.svg");
}
.avalilable-area {
   background-color: white;
   padding: 80px 0px;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center center;
}

.branh-sub-head {
   height: 100%;
   display: flex;
   align-items: center;
}

.branh-sub-head h5 {
   font-size: 32px;
   font-weight: 600;
   color: #4f1e00;
   padding: 4px 30px;
   margin-bottom: 10px;
   text-align: center;
   line-height: 50px;
}

.iFreme-cont iframe {
   width: 100%;
   min-height: 230px;
   border-radius: 10px;
   box-shadow:
      rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.subbranches-outer h3 {
   font-size: 20px;
   text-align: center;
   font-weight: 600;
   color: #81572e;
   margin-bottom: 12px;
}
/* ======= Branches  ======= */
/* ======= application partners  ======= */
.partners-form {
   background: #f6f5ef;
   padding: 50px 0px;
}

.partner-table {
   margin: 0px 0px 50px 0px;
   box-shadow:
      rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
   border-radius: 10px;
   overflow: hidden;
}
/* ======= application partners  ======= */
/* ======= service page  ======= */
.grids-cont-service {
   display: flex;
   flex-flow: column;
   gap: 30px;
   padding-bottom: 50px;
}

.service-grids {
   background: white;
   box-shadow:
      rgb(60 64 67 / 18%) 0px 1px 2px 0px,
      rgb(60 64 67 / 12%) 0px 1px 3px 1px;
   border-radius: 10px;
   overflow: hidden;
   background: linear-gradient(269deg, #ffdc80 0%, rgb(255 235 59 / 36%) 100.55%), #ffffff;
}

.service-grids-text {
   padding: 20px;
   padding-left: 0;
}

.service-grids-text h2 {
   font-size: 26px;
   margin-bottom: 20px;
   color: #4f1e00;
}

.service-grids-text p {
   font-size: 14px;
   margin-bottom: 10px;
   color: #414141;
}

.gold-srvc-img img {
}

.btn-srvc button {
   border: none;
   width: 100%;
   color: white;
   background: #4f1e00;
   font-weight: 500;
   font-size: 14px;
   max-width: 170px;
   margin-top: 10px;
   border-radius: 4px;
   padding: 9px 3px;
   border: none;
   display: none;
}

.service-grids:nth-child(even) {
   background: linear-gradient(90deg, #ffdc80 0%, rgb(255 235 59 / 36%) 100.55%), #ffffff;
}

.service-grids:nth-child(even) .service-grids-text {
   padding-left: 20px;
}
.services-container.careerimg .src-ico img {
   width: 60px;
   height: 50px;
}
/* ======= service page  ======= */
/* ======= marquee  ======= */
.marquee {
   height: 40px;
   overflow: hidden;
   background: linear-gradient(90deg, #ff9800 1.59%, #ffc107 84.61%);
   color: #333;
   border: none;
   position: fixed;
   bottom: 10px;
   right: 0;
   left: 0;
   display: flex;
   align-items: center;
   box-shadow:
      inset 0 -15px 30px rgb(0 0 0 / 21%),
      0 5px 10px rgb(0 0 0 / 29%);
   z-index: 1010;
   gap: 250px;
}

.marquee p {
   /* width: 100%; */
   margin: 0;
   text-align: center;
   transform: translateX(100%);
   animation: scroll-left 20s linear infinite;
   flex-shrink: 0;
   color: #66380a;
   font-size: 20px;
   font-weight: 500;
}
.marquee p span{
   padding:0px 15px;
}
@-moz-keyframes scroll-left {
   0% {
      -moz-transform: translateX(100%);
   }
   100% {
      -moz-transform: translateX(-100%);
   }
}

@-webkit-keyframes scroll-left {
   0% {
      -webkit-transform: translateX(100%);
   }
   100% {
      -webkit-transform: translateX(-100%);
   }
}

@keyframes scroll-left {
   0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
   }
   100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
   }
}
/* ======= marquee  ======= */
/* ======= career  ======= */
section.about-content.career-head .abt-cont h4 {
   font-size: 55px;
}
/* ======= career  ======= */
/* ======= faq new  ======= */
.doc-data {
   /* display: flex; */
}

.doc-list-on {
   width: 100%;
   padding: 12px 15px;
   flex-shrink: 0;
}

.doc-list-on h4 {
   font-size: 16px;
   font-weight: 500;
   /* text-align: center; */
}

.doc-list-data {
   margin-top: 15px;
}

.doc-list-data p {
   margin: 0;
}

.doc-list-data {
   display: flex;
   flex-flow: column;
   gap: 10px;
   font-size: 12px;
   color: #262626;
   /* text-align: center; */
}

.doc-list-data p span {
   color: red;
   font-weight: 500;
}
.col-md-3.m-auto.text-center.faq button {
   height: 40px !important;
   font-size: 12px;
   min-width: 150px;
   background: #cd865c;
   color: white;
   border-radius: 40px;
   min-height: 35px;
   font-weight: 500;
}
.col-4.faq.mb-2 button{
   height: 40px !important;
   font-size: 12px;
   min-width: 150px;
   background: #cd865c;
   color: white;
   border-radius: 40px;
   min-height: 35px;
   font-weight: 500;
   display: block;
}
/* ======= faq new  ======= */


@media (max-width: 767px) {
   section.carausel-main-section .carousel img {
      height: unset;
   }

   section.carausel-main-section .carousel-control-next {
      display: none;
   }

   a.carousel-control-prev {
      display: none;
   }

   .left-click-content {
      display: none;
   }

   .srv-gird-content {
      width: 100%;
   }

   .srv-grid-cont {
      gap: 80px;
   }

   .sub-hading h2 {
      font-size: 26px;
   }

   section.services-section {
      padding: 30px 0px;
   }

   .srv-gird-button {
      margin: 0;
   }

   section.full-width-calculate {
   }

   img.img-fluid.img-cal {
      height: 240px;
      object-fit: scale-down;
   }

   .gold-return-content {
      margin-top: 30px;
   }

   section.slider-section {
      padding: 50px 0px;
   }
   .inner-pages {
      padding-top: 100px;
   }

   .abt-cont h4 {
      font-size: 35px;
      margin-bottom: 20px;
   }

   .abt-cont p {
      font-size: 14px;
      margin-bottom: 20px;
   }

   .sub-hading h2 {
      font-size: 22px;
      margin-bottom: 25px;
      line-height: 32px;
   }

   section.history-section {
      padding: 30px 0px;
   }

   .history-content {
      flex-flow: wrap;
   }

   .hst-img {
      width: 100%;
   }

   span.rect-dots-top {
      width: 90px;
      left: -20px;
   }

   span.rect-dots-bottom {
      width: 90px;
      right: -20px;
   }

   .hst-right {
      padding: 0;
      width: 100%;
      padding-top: 60px;
   }

   section.vision-section {
      padding: 30px 0px;
   }

   .vsn-grids-outer {
      width: 100%;
   }

   .vsn-grids h4 {
      font-size: 20px;
      margin-bottom: 12px;
   }
   .formobicon img {
      width: 210px;
      margin: auto;
      display: block;
   }
   section.carausel-main-section .carousel {
      max-width: 100%;
   }

   .carousel-caption p {
      font-size: 16px;
   }

   section.carausel-main-section .carousel img {
      filter: brightness(0.5);
   }
   .gold-srvc-img img {
      max-height: 200px;
      object-fit: cover;
   }

   .service-grids-text {
      padding: 15px;
   }

   .service-grids:nth-child(even) .service-grids-text {
      padding-left: 15px;
   }

   .service-grids-text h2 {
      text-align: center;
      font-size: 18px;
      margin-bottom: 12px;
   }

   .service-grids-text p {
      text-align: center;
      font-size: 12px;
      margin-bottom: 10px;
      font-weight: 500;
   }

   .btn-srvc button {
      max-width: 100%;
      font-size: 12px;
   }
   section.about-content.career-head .abt-cont h4 {
      font-size: 28px;
  }
  .marquee p{
   flex-shrink: 0;
   font-size: 14px;
  }
}
